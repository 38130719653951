@tailwind base;
@tailwind components;
@tailwind utilities;

hr {
  background-image: linear-gradient(to right, rgba(169, 168, 168, 0), rgba(136, 136, 136, 0.75), rgba(157, 157, 157, 0));
}

.hero {
  background: linear-gradient(to bottom, rgba(22, 22, 22, 0.3) 0%, rgba(22, 22, 22, 0.5) 85%, #161616 100%);
}

.text-shadow {
  text-shadow: 0 0.625rem 1.5rem rgb(64 64 64 / 14%);
}

.cadre {
  background: linear-gradient(to right, #30323D 0%, #4D5061 100%, transparent 100%);
}

.card {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, .175);
}

@import url('https://fonts.googleapis.com/css2?family=Tajawal&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Almarai:wght@300&display=swap ");